<template>

<div class="relative bg-indigo-800">
  <div class="absolute inset-0">
    <img class="h-full w-full object-cover opacity-50" src="@/assets/contact-us.jpeg" alt="">
    <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
  </div>
  <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
    <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Contact Us</h1>
    <p class="mt-6 max-w-3xl text-xl text-indigo-100">We can be contacted via the following details:</p>
    
    <div class="flex flex-col mt-4 text-left">
      <div class="text-2xl font-semibold text-indigo-200 mb-2">
        <i class="fas fa-phone mr-2"></i> Phone
      </div>
      <div class="text-xl text-white mb-6">
        <a href="tel:+441200428102" class="text-white">+441200 428 102</a>
      </div>


      <div class="text-2xl font-semibold text-indigo-200 mb-2">
        <i class="fas fa-envelope mr-2"></i> Email
      </div>
      <div class="text-xl text-white mb-6">
        wecare@lewismitchellsolicitors.uk
      </div>
    </div>

    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full shadow-lg mt-8 text-xl mx-auto block" @click="$router.push('/')">
      Check My Claim for FREE <i class="fas fa-arrow-right ml-2"></i>
    </button>
  </div>
</div>


</template>

<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>

  
export default {
    name: 'ContactUs',
    metaInfo() {
      return {
        title: 'Contact Us | Lewis Mitchell - Your Finance Claims',
        meta: [
          {
            name: 'description',
            content: 'Claim back your mis-sold motor finance.',
          },
          {
            name: 'keywords',
            content: 'finance claim, mis-sold finance',
          },
          ],
    };
  },
};
</script>
  

  