<template>
  <header class="bg-white shadow-md py-3 relative z-50">
    <div class="container mx-auto flex justify-between items-center">
      <div class="logo-container">
        <a href="/"><img src="@/assets/my-missold-finance-logo-small.svg" alt="Lewis Mitchell Solicitors" class="ml-2 h-10 w-auto sm:h-20 logo-mobile-portrait" /></a>
      </div>
      <div class="text-lg font-bold flex items-center flex-col bg-gray-200 rounded-lg p-2 sm:text-base mr-1">
        <div class="flex items-center mb-1">
          <i class="fas fa-phone text-customColor  mr-2"></i>
          <a href="tel:+441200428102" class="text-customColor">01200 428 102</a>
        </div>
        <span class="text-xxs"></span>
      </div>
    </div>
  </header>
</template>


<script>
export default {
  name: "AppHeader",
  data() {
    return {
      menuOpen: false,
      isMobile: false,
    };
  },
  methods: {
    toggleMenu() {
      if (this.isMobile) {
        this.menuOpen = !this.menuOpen;
      }
    },
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 768;
    });
  },
};
</script>

<style>
  @media (orientation: portrait) {
    .logo-mobile-portrait {
      max-height: 3.50rem;
    }
  }
</style>
