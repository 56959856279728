<template>
  <div id="quiz-form" class='bg-white shadow-md rounded p-8' ref='quizForm'>


    <div class="text-center pb-4">
  <div class="mx-auto"> <!-- Center the image -->
    <img src="@/assets/car.png" alt="Description" class="mx-auto mb-4 w-40 h-auto"> <!-- Slightly increased width -->
  </div>
  <div class="flex justify-center items-center space-x-2">
    <span class="text-customColor font-extrabold text-lg md:text-xl">Check</span>
    <span class="text-black md:text-xl font-bold">My Claim</span>
  </div>
</div>





    <div v-if='currentQuestionIndex < questions.length'>
      <div class='flex items-center justify-between mb-1'>
        <div class='text-sm font-bold'>
          Step {{ currentQuestionIndex + 1 }} of {{ questions.length }}
        </div>
      </div>
      <div class='border-b-2 border-gray-200 mb-2'></div>
      <div class='text-xl mb-4 text-black font-semibold text-center px-2'>
        {{ questions[currentQuestionIndex].question }}
      </div>
      <select
        v-model='selectedAnswer'
        class='custom-dropdown group hover:bg-blue-200 cursor-pointer bg-gray-200 w-full rounded px-2 py-3 flex items-center mb-2'
      >
        <option value='' disabled>Select an option...</option>
        <option
          v-for='(answer, answerIndex) in questions[currentQuestionIndex].answers'
          :key='answerIndex'
          :value='answer'
        >{{ answer.text }}</option>
      </select>

        <!-- Safe, Secure, Confidential Text -->
  <div class='text-gray-500 flex items-center p-0 text-right pt-2'>
    <i class='text-green-600 fas fa-shield-alt mr-1'></i>
    Safe, secure &amp; confidential
  </div>
    
  <div class='border-b-2 border-gray-200 mb-2 mt-4'></div>

      <div class="mx-auto max-w-screen-xl w-full">
  <!-- Review Section -->
  <div class="flex flex-col sm:flex-row items-center my-8">
    <div class="mb-4 sm:mb-0 sm:flex-shrink-0">
      <!-- Image Placeholder -->
      <img src="@/assets/chris.png" alt="Customer Name" class="h-24 w-24 rounded-full">
    </div>
    <div class="flex flex-col items-center sm:items-start sm:ml-4 flex-1 max-w-md">
      <p class="text-lg font-semibold">Chris Keene</p>
      <img src="@/assets/reviews-stars.png" alt="5-star rating" class="w-20 mb-2">
      <p class="text-sm text-gray-600 text-center sm:text-left">"Lewis Mitchell solicitors have been a god send, their professional and personal manor and attention to detail has been fantastic and I could not recommend Michelle, Roger and the team enough, I would recommend them to everyone."</p>
    </div>
  </div>


</div>

    </div>
    <div v-else>
  <div v-if="loading" class='flex items-center'>
    <div class='spinner w-8 h-8'></div>
    <span class='ml-2'>Checking claim...</span>
      </div>
      <div v-else>
        <!-- New condition to check if both parameters are "No" -->
        <div v-if="bothParamsNo">
          <div class='text-md mb-4'>
            <div class='container mx-auto max-w-md'>
              <p class="pb-2">
                <!-- Custom message for the scenario where both params are "No" -->
                Unfortunately, based on your responses, you do not qualify for a claim at this time.
              </p>
            </div>
          </div>
          <button
          @click="reloadPage"
          class='bg-red-600 text-white font-bold py-3 px-4 rounded w-full shadow-md transform hover:shadow-lg transition duration-200'
        >
          Go Back and Try Again
        </button>

        </div>
        <div v-else>
          <!-- Original conditional rendering based on userQualifies -->
          <div v-if="userQualifies" class='text-md mb-4'>
            <div class='container mx-auto max-w-md'>
              <h2 class="text-3xl font-bold text-green-500">It's great news!</h2>
              <p class="pb-2">
  Based on the information you’ve provided, you may be eligible to make a claim. You could claim back up to
  <span class="font-semibold text-green-600 text-lg">£5,351.29**</span> 
  in compensation, we just need a few details.
</p>

            </div>
          </div>
          <div v-else class='text-md mb-4'>
            <div class='container mx-auto max-w-md'>
              <p class="pb-2">
                Sorry, it looks like you don't qualify.
              </p>
            </div>
          </div>
          <!-- The button related to the original logic -->
          <button id="free-cash-offer-button" class='bg-green-600 text-white font-bold py-3 px-4 rounded w-full shadow-md transform hover:shadow-lg transition duration-200' @click='submitQuiz()'>
            👉 Make My Claim for Me
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.custom-dropdown {
  border: 1px solid black;
  appearance: none;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<script>
export default {
  name: "QuizForm",
  data() {
    return {
      userQualifies: true, // Add this new property
      questions: [
        {
          question: 'Have you purchased at least one car on finance between 2007 and 2021?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
          ],
        },
        {
          question: 'Were you told about the Commission the Dealership would receive?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
          ],
        },
        {
          question: 'Were the different finance options presented and explained to you?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
          ],
        },
        
        {
  "question": "Please select your lender:",
  "answers": [
    { "text": "Don't know" },
    { "text": "I know it, but its not on the list" },
    { "text": "1st Stop Finance" },
    { "text": "1st Stop Finance Ltd" },
    { "text": "A1 Mortgages" },
    { "text": "ACF Car Finance Limited" },
    { "text": "Admiral Financial Services Ltd" },
    { "text": "Advantage Finance" },
    { "text": "Advantage Finance Limited" },
    { "text": "Advantage Finance Limited" },
    { "text": "ALD Automotive" },
    { "text": "Ald Automotive Limited" },
    { "text": "Aldermore Bank PLC" },
    { "text": "Alphabet Finance" },
    { "text": "Aqua Finance" },
    { "text": "ARG Personal Loans Ltd" },
    { "text": "Asda Money" },
    { "text": "Auto Accept Finance Limited" },
    { "text": "Auto Money Limited" },
    { "text": "Automotive Financial Services Ltd" },
    { "text": "Barclays Bank UK Plc" },
    { "text": "Bank Of Scotland plc" },
    { "text": "Billing Finance Limited" },
    { "text": "Black Horse" },
    { "text": "BMW Finance" },
    { "text": "BMW Financial Services (GB) Limited" },
    { "text": "Burnley Savings and Loans Limited" },
    { "text": "CA Auto Finance UK Ltd" },
    { "text": "Carfinance247 Limited" },
    { "text": "Carland of Wolverhampton" },
    { "text": "Citroen Financial Services" },
    { "text": "Close Motor Finance" },
    { "text": "Clydesdale Financial Services Limited" },
    { "text": "Conister Bank Limited" },
    { "text": "Creation Consumer Finance" },
    { "text": "FCE Bank Plc" },
    { "text": "Family Finance Limited" },
    { "text": "Fiat Auto Financial Services Ltd" },
    { "text": "FGA Capital UK Ltd" },
    { "text": "FirstRand Bank Limited" },
    { "text": "FirstRand Bank Ltd" },
    { "text": "Fortis Lease" },
    { "text": "GE Capital" },
    { "text": "GMAC" },
    { "text": "Go Car Credit Ltd" },
    { "text": "Hartwell Kidlington Ford" },
    { "text": "Hitachi Capital" },
    { "text": "Honda Finance Europe Plc" },
    { "text": "Hyundai Car Finance Limited" },
    { "text": "Hyundai Capital UK Ltd" },
    { "text": "ING Lease (UK) Limited" },
    { "text": "International Motors Finance Limited" },
    { "text": "J.D. Williams & Company Limited" },
    { "text": "Kia Finance" },
    { "text": "Land Rover Finance" },
    { "text": "Lloyds Banking Group" },
    { "text": "Lombard Bank Limited" },
    { "text": "Lombard North Central" },
    { "text": "Mitsubishi HC Capital UK Plc" },
    { "text": "MG Financial Services GB Ltd" },
    { "text": "MI Vehicle Finance Limited" },
    { "text": "Moneybarn No. 1 Limited" },
    { "text": "Morgan Stanley" },
    { "text": "Motonovo Finance Limited" },
    { "text": "Nissan Finance (GB) Limited" },
    { "text": "Northridge Finance" },
    { "text": "Ocean" },
    { "text": "Oodle Financial Services Limited" },
    { "text": "Other Lender" },
    { "text": "Paragon Banking Group PLC" },
    { "text": "Paragon Personal Finance" },
    { "text": "PCF Bank Limited" },
    { "text": "Peugeot Financial Services" },
    { "text": "Penrican Credit Limited" },
    { "text": "Premium Plan Limited" },
    { "text": "Private & Commercial Finance Group plc" },
    { "text": "Raphaels Bank" },
    { "text": "RCI Financial Services Limited" },
    { "text": "Renault - RCI Financial Services Limited" },
    { "text": "Secure Trust Bank" },
    { "text": "Secure Trust Bank Public Limited Company" },
    { "text": "SEAT Finance" },
    { "text": "Santander Consumer (UK) plc" },
    { "text": "Santander Motor Finance" },
    { "text": "Santander Plc" },
    { "text": "Santander UK PLC" },
    { "text": "Shogun Finance Ltd" },
    { "text": "Specialist Motor Finance Limited" },
    { "text": "Stellantis Financial Services UK Limited" },
    { "text": "Suzuki Finance GB Ltd" },
    { "text": "Suzuki Financial Services Limited" },
    { "text": "Tandem Motor Finance Limited" },
    { "text": "The Funding Corporation" },
    { "text": "Toyota Financial Services" },
    { "text": "Vauxhall Finance plc" },
    { "text": "Volkswagen Financial Services (UK) Limited" },
  ]
},



        {
          question: 'If you have purchased additional vehicles on PCP, please tell us how many more:',
          answers: [
            { text: '1' },
            { text: '2' },
            { text: '3' },
            { text: '4' },
            { text: '5+' },
            { text: 'No More Veichles' },
        ]
        },
        {
          question: 'Do you still have the finance agreements?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' },
        ]
        },
      ],
      currentQuestionIndex: 0,
      answers: [],
      selectedAnswer: '',
      loading: false,
    };
  },
  watch: {
    selectedAnswer(answer) {
      if (answer) {
        this.answers.push(answer);
        this.selectedAnswer = '';
        this.currentQuestionIndex++;
        
        if (this.currentQuestionIndex >= this.questions.length) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 3000);
        }
      }
    },
  },
  methods: {
    reloadPage() {
    window.location.reload();
  },

    constructQuizData() {
      return {
        apiParam1: this.answers[0].text,
        apiParam2: this.answers[1].text,
        apiParam3: this.answers[2].text,
        apiParam4: this.answers[3].text,
        apiParam5: this.answers[4].text,
        apiParam6: this.answers[5].text,
      };
    },
    submitQuiz() {
  const firstTwoAnswersAreNo = this.answers[0].text === 'No' && this.answers[1].text === 'No';
  
  // Update userQualifies based on the condition
  this.userQualifies = !firstTwoAnswersAreNo;

  if (!this.userQualifies) {
    // User does not qualify, so we don't need to emit 'completed' or show "Good news"
    // You might want to show a different section or message here
    return; // Exit the function early
  }

  // If user qualifies, proceed as before
  const quizData = this.constructQuizData();
  this.$emit('completed', quizData);
},

},

  mounted() {
    
  },
  computed: {
  bothParamsNo() {
    // Check if both apiParam1 and apiParam2 equal to "No"
    return (this.answers[0].text === 'No' && this.answers[1].text === 'Yes') || 
           (this.answers[0].text === 'Yes' && this.answers[1].text === 'Yes') || (this.answers[0].text === 'No' && this.answers[1].text === 'No') ;
  }
}

};
</script>

