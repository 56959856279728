<template>
  <div class="relative" style="height: auto;">
    <AppHeader />
    <router-view></router-view>
    <div
      v-if="$route.path === '/'"
      class="header-container flex flex-col items-center bg-cover bg-center relative"
      :style="`background-image: url(${headerImageUrl})`"
    >
    <div class="overlay absolute inset-0 bg-blue-950 opacity-90"></div>      
      <div class="flex flex-col items-center z-10" :class="{ 'hidden': showQuiz || showContact }">
        <div class="key-points-container text-left sm:text-center pt-0 sm:pt-20 md:pt-10 px-5 sm:px-0">
          <h1 class="text-xl md:text-3xl font-extrabold text-white text-center pt-0 sm:text-center px-2">Did you buy a motor vehicle on finance between 2007 & 2021?</h1>
          <ul class="mt-4 flex space-x-0 sm:space-x-4 md:space-x-6 justify-start sm:justify-center flex-wrap text-left text-white">
            <li class="flex items-center mb-0 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Check car finance agreements
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i>No Win No Fee Basis
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Quick and simple process
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> We do all the work
            </li>
          </ul>
          <p class="text-sm text-white mt-2">**Includes cars, vans, camper vans and motorbikes</p>
        </div>
        <AddressForm @addressSelected="onAddressSelected" class="w-full px-4 sm:w-1/2 sm:px-0 mt-4" />

<div class="mt-4 small-device-text">
  <div class="w-full sm:max-w-sm mx-auto text-sm text-gray-300 pb-10">
    <p class="text-xs font-bold mb-2">
  <span class="text-red-500 mr-1">&#9888;</span>
</p>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center pt-10 mx-4 justify-start z-10" :class="{ 'hidden': !showQuiz }">
        <h1 class="text-xl md:text-3xl font-extrabold text-white text-center pt-0 sm:text-center px-2">Did you buy a motor vehicle on finance between 2007 & 2021?</h1>
        <h2 class="text-base md:text-lg font-bold text-white text-center pt-2 sm:text-center px-2">You could reclaim £1,000s. Check your potential compensation now!</h2>


          <ul class="mt-4 flex space-x-0 sm:space-x-4 md:space-x-6 justify-start sm:justify-center flex-wrap text-left text-white">
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Check car finance agreements
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> No Win No Fee Basis
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Quick and simple process
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> We do all the work
            </li>
          </ul>
          <p class="text-sm text-white mt-2">**Includes cars, vans, camper vans and motorbikes</p>

        <QuizForm @completed="onQuizComplete" class="mt-3"/>
        <div class="sm:max-w-lg mx-auto text-sm text-white pt-5 pb-10" style="font-size: 10px;">
  <span class="text-red-500 mr-1">&#9888;</span>
  As a reputable legal firm, we charge 25% of your claim amount. You may also engage your own legal counsel or file a complaint directly with the lender. If dissatisfied, the Financial Ombudsman offers a free service.
</div>

      </div>
      

      <div class="flex flex-col items-center pt-10 justify-start z-10 pb-10 w-full" :class="{ 'hidden': !showContact }">

        <ContactForm :address-data="addressData" 
                      :quiz-data="quizData" 
                      :gclid="gclid" 
                      :utmCampaign="utmCampaign" 
                      :utmSource="utmSource" 
                      :utmMedium="utmMedium"
                      :utmKeyword="utmKeyword"
                      :utmMatch="utmMatch" 
                      :utmDevice="utmDevice" />
      </div>
    </div>
  </div>

  <div class="bg-gray-100 py-8 pb-12">
  <div class="container mx-auto px-4">
    <h2 class="text-2xl font-bold text-center text-black mb-6">What Our Customers Say</h2>
    <div class="flex justify-center mb-6">
      <img src="@/assets/reviews-trust.png" alt="Trustpilot Reviews" class="w-auto h-20"> <!-- Adjust width and height as needed -->
    </div>
    <div class="grid gap-6 md:grid-cols-3">
      <!-- Review 1 -->
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <div class="flex items-center mb-4">
          <img src="@/assets/janet.png" alt="Reviewer 1" class="w-12 h-12 rounded-full mr-4">
          <div>
            <p class="font-semibold text-lg">Janet Bialecki</p>
            <p class="text-yellow-500 flex items-center">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </p>
          </div>
        </div>
        <p class="text-gray-600">"I instructed Asaf Niaz to act on my behalf for a settlement agreement, and I honestly can not believe the speed in which he acted for me. Extremely helpful, friendly yet professional at all times"</p>
      </div>
      <!-- Review 2 -->
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <div class="flex items-center mb-4">
          <img src="@/assets/73x73.png" alt="Reviewer 2" class="w-12 h-12 rounded-full mr-4">
          <div>
            <p class="font-semibold text-lg">
              René Bradley</p>
            <p class="text-yellow-500 flex items-center">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </p>
          </div>
        </div>
        <p class="text-gray-600">"Asaf Niaz and his team are true professionals who always go the extra mile to make sure they give you the best service possible. My case is still on going but they will work around the clock 7 days a week to get the best possible results. Very friendly and personable."</p>
      </div>
      <!-- Review 3 -->
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <div class="flex items-center mb-4">
          <img src="@/assets/wallbank.png" alt="Reviewer 3" class="w-12 h-12 rounded-full mr-4">
          <div>
            <p class="font-semibold text-lg">
              Deborah Wallbank</p>
            <p class="text-yellow-500 flex items-center">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </p>
          </div>
        </div>
        <p class="text-gray-600">"I was given extremely informative and helpful free advice which proved invaluable!
Mr Niaz was very approachable and knowledgeable in this area and I left feeling reassured and confident going forward.
Would highly recommend and will definitely use this company in the future, thank you!"</p>
      </div>
    </div>
  </div>
</div>



  <div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/landerV1/') && !$route.path.startsWith('/landerV2/') && !$route.path.startsWith('/thank-you') && !$route.path.startsWith('/location')" class="pb-6 guaranteed-offer">
    <h3 class="text-2xl font-bold text-center text-black mb-8 mt-10">How It Works</h3>
      <div class="offer-steps">

        <div class="step">
          <i class="fas fas fa-clock clock-icon text-customColor"></i>
          <p>Check Eligibility In<br /><strong>Under 30 Seconds</strong></p>
        </div>
        <div class="step">
          <i class="fas fa-piggy-bank piggy-icon text-customColor"></i>
          <p>No Claim?<br /><strong>You Don’t Owe A Penny</strong></p>
        </div>
        <div class="step">
          <i class="fas fa-shield-alt globe-icon text-customColor"></i>
          <p>We Will Do All The Leg Work<br /><strong>Sit Back & Relax</strong></p>
        </div>
      </div>
      <p class="px-4 mb-4">
        Our service is simple, stress-free, giving your peace of mind.
      </p>
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full shadow-lg mt-8 text-xl mx-auto block" @click="goToTop">
      Check My Claim for FREE <i class="fas fa-arrow-right ml-2"></i>
    </button>
    </div>

    <div class="bg-gray-300 pb-6">
  <div class="px-5 pt-6 pb-4 mx-auto max-w-screen-xl w-full">
    <h2 class="text-center font-bold text-2xl text-black-500 mb-6">Key Facts</h2>
    <div class="flex flex-wrap justify-center gap-4">
      <div class="min-w-[300px] max-w-[350px] w-full md:w-auto bg-white shadow-lg rounded-lg p-4">
        <p class="text-3xl font-semibold text-gray-800">£41 Billion</p>
        <p class="text-sm text-gray-600 mt-2">The overall borrowing linked to car financing in 2021 underscores the substantial magnitude of the car finance industry.</p>
      </div>
      <div class="min-w-[300px] max-w-[350px] w-full md:w-auto bg-white shadow-lg rounded-lg p-4">
        <p class="text-3xl font-semibold text-gray-800">£5,351.29</p>
        <p class="text-sm text-gray-600 mt-2">The amount of compensation a customer could be eligible to claim if found to be a victim of PCP agreement mis-sell.</p>
      </div>
      <div class="min-w-[300px] max-w-[350px] w-full md:w-auto bg-white shadow-lg rounded-lg p-4">
        <p class="text-3xl font-semibold text-gray-800">90%</p>
        <p class="text-sm text-gray-600 mt-2">Over 90% of car finance agreements currently utilise Personal Contract Purchase (PCP) loans, highlighting their dominant position within the industry.</p>
      </div>
    </div>
  </div>

</div>










<div v-if="!($route.path.startsWith('/landerV1/') || $route.path.startsWith('/landerV2/') || $route.path.startsWith('/articles/') || $route.path.startsWith('/thank-you') || $route.path.startsWith('/location'))" class="bg-gray-100 flex justify-center items-center text-gray-800 p-8">
  <div class="max-w-3xl p-6 bg-white rounded-lg shadow-lg">
    <h2 class="text-3xl font-bold mb-8 text-left text-customColor">Was You Mis-sold Car Finance?</h2>
    <img src="@/assets/car-claim-money.jpg" alt="Mis-Sold Car Finance Concept" class="mx-auto my-4 rounded-lg shadow-md" style="max-width:100%; height:auto;">

    <div class="space-y-4 px-4">
      <p class="text-lg">Seeking justice for a finance package that was mis-sold to you, costing you more than it should?</p>
      <p class="text-lg">Look no further! Lewis Mitchell Solicitors are here to help. With our expert knowledge and dedication, we're on your side to claim what you're owed.</p>
      <p class="text-lg">Dealing with the complexities of car finance mis-selling can be daunting. Choosing Lewis Mitchell Solicitors means you're opting for a process aimed at recovering your rightful compensation without the hassle.</p>
      <p class="text-lg">Please note, while reclaiming may seem like a challenge, our service is designed to be straightforward and cost-effective. With no upfront fees, we take care of everything, ensuring transparency and efficiency.</p>
      <p class="text-lg">As your advocate, Lewis Mitchell Solicitors offer complete peace of mind and support throughout the claim process, ensuring a stress-free experience as we work to secure your compensation.</p>
    </div>
    
    <!-- Case Study Section -->
    <div class="mt-10 py-6 px-8 bg-gray-50 rounded-lg shadow-md">
      <h3 class="text-2xl font-semibold mb-4 text-customColor">Success Story: Tom's Claim</h3>
      <p class="mb-4">The Financial Ombudsman Service has shared complaints it received, including Tom's, whose claim was upheld after it was found his lender failed to conduct proper affordability checks:</p>
      <p class="mb-4">Struggling to meet his car finance repayments and facing the risk of impacting his credit file, Tom, a 24-year-old renting and working with a take-home pay of £1,200, challenged his lender through the FOS.</p>
      <p class="mb-4">His finance agreement of £245 monthly over three years, on top of living expenses and debt, was deemed unaffordable by the Ombudsman. The finance provider was ordered to cancel the remaining finance amount, take back the car, correct Tom's credit file, and refund his deposit with interest, rectifying the mis-sold agreement.</p>
    </div>
    
    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full shadow-lg mt-8 text-xl mx-auto block" @click="goToTop">
      Check My Claim for FREE <i class="fas fa-arrow-right ml-2"></i>
    </button>
  </div>
</div>




<!-- FAQs Section -->
<div id="app" class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12" v-if="!$route.path.startsWith('/articles/') && $route.path !== '/thank-you'">
  <div class="text-center">
    <h2 class="text-3xl font-bold text-customColor">Frequently Asked Questions</h2>
  </div>
  <div class="mt-8">
    <dl class="space-y-6">
      <div v-for="(item, index) in faqs" :key="index" class="space-y-2">
        <dt class="cursor-pointer px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-200" @click="toggle(index)" :class="{'text-gray-900': item.isOpen, 'text-gray-700': !item.isOpen}">
          <div class="flex justify-between items-center">
            {{ item.question }}
            <svg :class="{'rotate-180': item.isOpen}" class="inline-block w-4 h-4 transition-transform duration-200" viewBox="0 0 24 24">
              <path d="M19 9l-7 7-7-7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </dt>
        <dd v-show="item.isOpen" class="text-gray-600 px-4 py-2 bg-gray-100" v-html="item.answer"></dd>
      </div>
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full shadow-lg mt-8 text-xl mx-auto block" @click="goToTop">
      Check My Claim for FREE <i class="fas fa-arrow-right ml-2"></i>
    </button>
    </dl>
  </div>
</div>




<div v-if="!$route.path.startsWith('/articles/')" class="bg-gray-200 py-8">
  <div class="max-w-3xl mx-auto">
    <h2 class="text-2xl font-bold mb-4 text-center">Car finance claims have been featured in:</h2>
    <div class="flex flex-wrap justify-center items-center">
      <div class="mx-4 my-2">
        <img src="@/assets/logo1.png" alt="Logo 1" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo2.png" alt="Logo 2" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo3.png" alt="Logo 3" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo4.png" alt="Logo 4" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo5.png" alt="Logo 5" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo6.png" alt="Logo 6" class="h-12">
      </div>
    </div>
  </div>
</div>
<!---
<div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/thank-you')" class="mx-auto p-4 max-w-3xl">
  <ArticleList />
</div> -->


<footer class="bg-gray-900 text-white py-8">
  <div class="max-w-3xl mx-auto flex flex-col items-center">
    <!-- Uncomment if image is needed -->
    <!-- <img src="@/assets/my-missold-finance-logo-small.svg" alt="Motor Finance Help" class="h-12 mb-4"> -->
    
    <p class="text-center text-sm mb-4 px-2">*Reference to the FCA findings are taken from their ‘<a href="/Fca-Motor-Financing.pdf" class="underline text-blue-500 hover:text-blue-300" target="_blank" rel="noopener noreferrer">Our work on motor finance – final findings March 2019</a>’ report</p>
    
    <nav class="w-full mb-4">
      <ul class="flex flex-col items-start justify-start space-y-2 md:flex-row md:items-center md:justify-center md:space-y-0 md:space-x-4 pl-4">
        <li>
          <a href="/privacy-policy" class="hover:text-gray-300">Privacy Policy</a>
        </li>
        <li>
          <a href="/terms-of-use" class="hover:text-gray-300">Terms of Use</a>
        </li>
        <li>
          <a href="/cookies-policy" class="hover:text-gray-300">Cookie Policy</a>
        </li>
        <li>
          <a href="/contact-us" class="hover:text-gray-300">Contact Us</a>
        </li>
      </ul>
    </nav>

    <!-- Additional content and legal notices -->
    <div class="flex pb-2 items-center">
      <!-- Placeholder for additional images or icons -->
      <!-- <img src="@/assets/ls_logo.png" alt="The Law Society" class="h-16 mx-2"> -->
      <!-- SRA Digital Badge code placeholder -->
    </div>

    <p class="text-center py-2 px-4 text-sm sm:text-base">LEWIS MITCHELL LIMITED is a company registered in England and Wales, registered company number 13010480 which is authorised and regulated by the Solicitors Regulation Authority SRA Number 8003729.</p>
    <p class="text-center py-2 text-xs sm:text-sm pl-2 pr-2">Address: Lewis Mitchell Solicitors, Primrose Dairy, Railway View Road, Clitheroe, BB7 2HE</p>
    <p class="text-center text-xs sm:text-sm text-gray-500 px-4 py-2">
      ©2024 LEWIS MITCHELL LIMITED. All rights reserved.
    </p>
  </div>
</footer>




</template>



<script>
import AppHeader from "@/components/Header.vue";
import AddressForm from "./components/AddressForm.vue";
import QuizForm from "./components/QuizForm.vue";
import ContactForm from "./components/ContactForm.vue";
//import CountdownTimer from "./components/CountdownTimer.vue";
//import ArticleList from './components/ArticleList.vue';


export default {
  components: {
    AppHeader,
    AddressForm,
    QuizForm,
    ContactForm,
    //CountdownTimer,
   // ArticleList, // Add this line to register the ArticleList component

  },
  data() {
    return {
      addressData: {
        street: "",
        postcode: "",
      },
      quizData: {},
      showQuiz: true, // Set this to true so the quiz shows first
      showContact: false,
      showNewSection: false, // Add this line
      address: {},
      utmCampaign: '',
      utmSource: '',
      utmMedium: '',
      utmKeyword: '',
      utmDevice: '',
      headerImageUrl: require("@/assets/car-finance-claim.jpg"),
      faqs: [
  {
      question: 'How Easy Is It To File A Car Finance Claim?',
      answer: 'Discover in just 30 seconds whether you might be eligible for a compensation claim with Lewis Mitchell Solicitors. Plus, checking is totally free!',
      isOpen: false
  },
  {
      question: 'How Do I Know If I Have A Car Finance Claim?',
      answer: 'If you\'ve entered into a car finance contract in the past ten years, particularly a PCP (Personal Contract Purchase) or HP (Hire Purchase) plan, it\'s highly likely you may be entitled to a refund from your finance provider. The FCA discovered that hidden commissions affected as many as 90% of car finance deals*.',
      isOpen: false
  },
  {
      question: 'What Requirements Must I Fulfill?',
      answer: 'You\'re eligible to claim if you financed a vehicle in England, UK, from January 2007 to January 2021, and weren\'t informed about lender commissions, faced high interest rates, or the lender failed to evaluate your financial capacity.',
      isOpen: false
  },
  {
      question: 'Why use Lewis Mitchell Solicitors',
      answer: 'Opting for Lewis Mitchell Solicitors for your compensation claims offers expertise, time-saving, no upfront costs, higher success rates, stress reduction, and maximised claims, making it a more efficient and potentially more successful choice than navigating complex car finance issues alone.',
      isOpen: false
  },
  {
  question: 'Why is it best to use a solicitor to fight for your compensation claim?',
  answer: `
    <p>Choosing a solicitor to handle your compensation claim offers several significant advantages that can enhance the effectiveness, legality, and potential success of your claim:</p>
    <ul>
      <li><strong>Expert Legal Knowledge</strong>: Solicitors are highly trained in the law and have a deep understanding of the legal procedures and standards required to effectively navigate the complexities of compensation claims. They can interpret and apply legal statutes and case law to your advantage.</li>
      <li><strong>Professional Representation</strong>: Having a solicitor represent you means that you have a professional advocate who understands how to present your case in the most legally compelling way. Solicitors can manage all communications with the other party or their insurers, reducing the likelihood of being pressured into an unfavorable settlement.</li>
      <li><strong>Higher Compensation Amounts</strong>: Studies and data consistently show that claimants represented by solicitors typically receive higher compensation amounts compared to those who handle claims on their own. Solicitors know how to properly quantify all aspects of your claim, including future losses and non-economic damages like pain and suffering.</li>
      <li><strong>Contingency Fee Basis</strong>: Many solicitors work on a contingency fee basis, meaning they only get paid if you win your case. This arrangement aligns the solicitor's interests with yours and motivates them to obtain the best possible outcome.</li>
      <li><strong>Stress Reduction</strong>: Navigating the legal aspects of a compensation claim can be stressful and time-consuming, especially for individuals who are also dealing with injuries or emotional distress. A solicitor can take on the burden of the legal process, allowing you to focus on recovery.</li>
      <li><strong>Negotiation Skills</strong>: Solicitors have the negotiation skills needed to deal with the other party’s lawyers and insurance companies. They are experienced in negotiating settlements that accurately reflect the true value of your claim, often securing a better outcome than you might achieve on your own.</li>
      <li><strong>Litigation Experience</strong>: If your claim cannot be settled amicably, a solicitor can file a lawsuit on your behalf and represent you in court. Their litigation experience is invaluable in these scenarios, where understanding procedural nuances and presenting strong evidence is crucial.</li>
      <li><strong>No Upfront Costs</strong>: Since many solicitors work on a contingency basis, you often do not have to pay any upfront fees. All legal costs are recovered as a portion of the settlement or award, making legal representation accessible even without immediate funds.</li>
      <li><strong>Regulated Professional Standards</strong>: Solicitors are regulated by professional bodies and are required to maintain high ethical standards. They are accountable to regulatory authorities, which can offer you protection and recourse in the event of any professional misconduct.</li>
      <li><strong>Save Time and Avoid Mistakes</strong>: The legal system can be complex and fraught with potential pitfalls. A minor error in handling your claim can be detrimental to the outcome. Solicitors ensure that all aspects of the claim process are handled correctly, including meeting critical deadlines and filing necessary documentation accurately.</li>
    </ul>
    <p>In summary, using a solicitor to handle your compensation claim not only enhances your chance of receiving fair compensation but also provides peace of mind and professional guidance through a potentially challenging legal process.</p>
  `,
  isOpen: false
},
{
    question: 'How much does it cost to chase my claim?',
    answer: `
      <p>Lewis Mitchell Solicitors operates on a contingency fee basis, meaning you only pay if your claim is successful. Our fee is 25% of the compensation amount recovered. If we do not win your case and recover compensation, you owe us nothing. This ensures that our interests are aligned with yours, and we are motivated to achieve the best possible outcome for your claim.</p>
    `,
    isOpen: false
  },
],

    };
  },
  methods: {
    goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onAddressSelected(address) {
      this.addressData = address;
      this.showQuiz = true;
    },
    onQuizComplete(quizResults) {
      this.quizData = quizResults;
      this.showQuiz = false;
      this.showContact = true;
    },
    parseUrlParameters() {
    const urlParams = new URLSearchParams(window.location.search);
    this.utmCampaign = urlParams.get('utm_campaign') || '';
    this.utmSource = urlParams.get('utm_source') || '';
    this.utmMedium = urlParams.get('utm_medium') || '';
    this.utmKeyword = urlParams.get('utm_keyword') || '';
    this.utmDevice = urlParams.get('utm_device') || '';
    this.utmMatch = urlParams.get('utm_matchtype') || '';
    this.adId = urlParams.get('adid') || '';
    this.adSetId = urlParams.get('adsetid') || '';
    this.gclid = urlParams.get('gclid') || '';
    },
    toggle(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    },
  },

  created() {
    this.parseUrlParameters();
    },
};
</script>

<style>
.header-container {
  /* Other styles */
  position: relative; /* Add relative positioning */
}

@media screen and (max-width: 640px) {
  .sm\:max-w-sm {
    max-width: 84%;
  }
}

.guaranteed-offer {
  margin: 20px 0;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.guaranteed-offer h2 {
  font-size: 2.5rem; 
  font-weight: bold; 
  margin-bottom: 15px; 
}

.offer-steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex-basis: 30%; 
  box-sizing: border-box; 
}

.clock-icon,
.piggy-icon,
.globe-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .step {
    flex-basis: 100%;
  }
}

.bg-custom-color {
  background-color: blue;
}

@media (max-width: 640px) {
  .small-device-table {
      font-size: 14px;
    }

  .small-device-table td,
  .small-device-table th {
      padding: 4px 8px;
    }
  }

.small-device-table tfoot {
      font-size: 6px;
}

/* Adding hover link styles */
.hover-link {
  transition: color 0.3s, transform 0.3s;
}

.hover-link:hover {
  color: red; /* Changing color on hover */
  transform: scale(1.05); /* Adding a little scaling effect on hover */
}

.text-xxs {
  font-size: 8px; /* or whatever size fits your design */
}

/* Adding space around paragraphs and list items */
dd p, dd li {
  margin-bottom: 0.5rem; /* Adds a little space below each paragraph and list item */
}

/* Ensure ul has proper padding if not appearing as expected */
dd ul {
  padding-left: 20px; /* Ensures bullet points are indented properly */
}

/* Adjust line height for better readability */
dd p, dd li {
  line-height: 1.6; /* Increases line height for better readability */
}
</style>

