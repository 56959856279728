import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/styles.css';
import { createRouter, createWebHistory } from 'vue-router';
import ContactUs from './components/ContactUs.vue';
import ThankYou from './components/ThankYou.vue';
import EmptyContent from './components/EmptyContent.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import TermsOfUse from './components/Terms.vue';
import CookiesPolicy from './components/CookiesPolicy.vue';
import ArticlePage from './components/ArticlePage.vue';
import NotFound from './components/404.vue';

const routes = [
  { path: '/', component: EmptyContent },
  { path: '/contact-us', component: ContactUs },
  { path: '/thank-you', component: ThankYou },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/terms-of-use', component: TermsOfUse },
  { path: '/cookies-policy', component: CookiesPolicy },
  { path: '/articles/:slug', component: ArticlePage, name: 'article' },
  { path: '/404', component: NotFound },
  { path: '/:catchAll(.*)', redirect: '/404' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

// Custom directive: click-outside
app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
});

router.beforeEach((to, from, next) => {
  const pageTitle = to.matched.reduce((title, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      title = routeRecord.components.default.metaInfo().title;
    }
    return title;
  }, '');

  const metaDescription = to.matched.reduce((description, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      description = routeRecord.components.default.metaInfo().meta[0].content;
    }
    return description;
  }, '');

  const metaKeywords = to.matched.reduce((keywords, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      keywords = routeRecord.components.default.metaInfo().meta[1].content;
    }
    return keywords;
  }, '');

  document.title = pageTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="keywords"]').setAttribute('content', metaKeywords);

  const existingCanonical = document.querySelector('link[rel="canonical"]');
  if (existingCanonical) {
    existingCanonical.parentNode.removeChild(existingCanonical);
  }

  const canonical = document.createElement('link');
  canonical.setAttribute('rel', 'canonical');
  canonical.setAttribute('href', window.location.origin + to.fullPath);
  document.head.appendChild(canonical);

  next();
});

app.use(router).mount('#app');
