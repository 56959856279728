<template>
  <div></div>
</template>

<script>
export default {
  name: 'EmptyContent',
  metaInfo() {
    return {
      title: 'Lewis Mitchell Solicitors - Claim back Mis-sold Car Finance - Check Online in 60 Seconds',
      meta: [
        {
          name: 'description',
          content: 'Lewis Mitchell Solicitors - Claim back Mis-sold Car Finance - Check Online in 60 Seconds',
        },
        {
          name: 'keywords',
          content: 'car finance mis-sold, claim car finance, mis-sold car finance',
        },
      ],
    };
  },
};
</script>
