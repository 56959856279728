<template>

  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/contact-banner.png" alt="Motor Finance Claim">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>

    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">

      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-3xl lg:text-3xl">Thank You for Submitting Your Motor Finance Claim</h1>

<p class="mt-6 max-w-3xl text-xl text-green-500">What Happens Next?</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
  We have received your claim and will begin reviewing it promptly. You will receive an E-sign document via email or SMS once we have completed our initial assessment. If we require any additional information, we will reach out to you.
</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
  We will carefully validate your claim and inform you of any compensation you may be entitled to.
</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
  Please note that the Financial Conduct Authority (FCA) initiated an investigation in January 2024. We anticipate their report around September 25, 2024, which will clarify who will bear the cost of compensation.
</p>

<p class="mt-6 max-w-3xl text-xl text-indigo-100">
  Should you have any questions or require further assistance, please do not hesitate to contact our team. Thank you for trusting us with your claim. We are here to support you every step of the way.
</p>


      <div class="flex flex-col mt-4 text-left">
        



        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Phone
        </div>
        <div class="text-xl text-white mb-6">
          01200428 102
        </div>
  
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Email
        </div>
        <div class="text-xl text-white mb-6">
          wecare@lewismitchellsolicitors.uk
        </div>
      </div>
    </div>
  </div>
  </template>

<style>
.opacity-50 {
    opacity: 0.4;
}
</style>
    
    <script>
    export default {
      name: 'ThankYou',
      metaInfo() {
        return {
          title: 'Thank You - Lewis Mitchell Solicitors - Your Finance Claims',
          meta: [
            {
              name: 'description',
              content: 'Your Finance Claims Confirmation',
            },
            {
              name: 'keywords',
              content: 'car finance claim, motor finance claim',
            },
          ],
          link: [
        { rel: 'canonical', href: 'https://yourfinanceclaims.co.uk/thank-you' }
        ]
        };
      },
    };
    </script>
  
    