<template>
  <div class="container mx-auto mt-0 p-0 bg-gray-100 rounded-md shadow-lg w-full max-w-lg sm:max-w-md md:max-w-xl lg:max-w-2xl">
    <div class="bg-gray-100 p-8 rounded-md">
      <h2 class="text-xl md:text-3xl font-semibold mb-4">Make My Claim Online</h2>

      <!-- Step 1: Personal Information -->
      <div v-if="currentStep === 1">
        <form @submit.prevent="nextStep">
          <div class="mb-4">
            <input
              class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
              type="text"
              v-model="firstName"
              placeholder="First Name"
              @blur="validateFirstName"
            />
            <p class="text-red-500 text-xs italic" v-if="firstNameError">{{ firstNameError }}</p>
          </div>

          <div class="mb-4">
            <input
              class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
              id="lastName"
              type="text"
              v-model="lastName"
              placeholder="Last Name"
              @blur="validateLastName"
            />
            <p class="text-red-500 text-xs italic" v-if="lastNameError">{{ lastNameError }}</p>
          </div>

          <!-- Date of Birth Field -->
          <div class="mb-4">
            <label for="dateOfBirth" class="block text-gray-700 text-sm font-bold mb-2">Date of Birth</label>
            <input
              class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
              id="dateOfBirth"
              type="date"
              v-model="dateOfBirth"
              placeholder="YYYY-MM-DD"
              @input="clearDateOfBirthError"
            />
            <p v-if="dateOfBirthError" class="text-red-500 text-xs italic mt-1">{{ dateOfBirthError }}</p>
          </div>


          <div class="flex flex-col sm:flex-row">
            <label for="postcode" class="mb-0 sm:mb-0 sm:mr-4">Your Address:</label>
            <input v-model="postcode" @input="handlePostcodeInput" class="mobile-input bg-gray-200 border-2 border-blue-300 w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 text-lg bg-white" id="postcode" type="text" placeholder="Enter your Postcode..." />
            <button class="select-address-btn bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 font-bold transition duration-300 ease-in-out text-base mt-2 sm:mt-0 sm:ml-4 sm:px-6 sm:py-2 sm:text-xs" @click.prevent="searchAddress">Select Address</button>
          </div>

          <div class="relative mt-2" v-click-outside="closeSuggestions">
            <ul v-if="addressSuggestions.length > 0 && suggestionsVisible" class="absolute z-10 bg-white rounded shadow-lg text-gray-700 max-h-64 overflow-y-auto">
              <li v-for="suggestion in addressSuggestions" :key="suggestion.id" class="py-2 px-3 hover:bg-gray-200 cursor-pointer" @click="selectAddress(suggestion)">
                {{ suggestion.address }}
              </li>
            </ul>
          </div>

          <div v-if="selectedAddress" class="selected-address bg-gray-50 p-2 mt-2 mb-2 rounded">
            <p class="text-sm font-medium text-gray-800">Selected Address:</p>
            <p class="text-sm text-gray-700">{{ selectedAddress }}</p>
          </div>

          <p class="text-red-500 text-xs italic" v-if="addressError">{{ addressError }}</p>

          <div class="mb-4">
            <input
              class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
              id="email"
              type="text"
              v-model="email"
              placeholder="Email"
              @blur="validateEmail"
            />
            <p class="text-red-500 text-xs italic" v-if="emailError">{{ emailError }}</p>
          </div>

          <div class="mb-4">
            <input
              class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
              id="phone"
              type="text"
              v-model="phone"
              placeholder="Contact Number"
              @blur="validatePhone"
            />
            <p class="block text-xs font-medium text-gray-500 small-device-text">We need a valid number</p>
            <p class="text-red-500 text-xs italic" v-if="phoneError">{{ phoneError }}</p>
          </div>

          <!-- Multi-text field for entering car registrations -->
          <div class="mb-4">
            <label for="carRegistrations" class="block text-gray-700 text-sm font-bold mb-2">
              Enter any car registrations you can remember:
            </label>
            <textarea
              id="carRegistrations"
              v-model="carRegistrations"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              rows="3"
              placeholder="e.g., ABC123, XYZ789 (Optional)"
            ></textarea>
          </div>

          <div class="flex justify-end">
            <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Next</button>
          </div>
          <p class='text-gray-500 flex items-center p-0 text-right pt-2'>
            <i class='text-green-600 fas fa-shield-alt mr-1'></i>
            Safe, secure &amp; confidential
          </p>
        </form>
      </div>

      <!-- Step 2: Signature -->
      <div v-if="currentStep === 2">
        <div class="text-grey">
          <div class="text-sm sm:text-base leading-relaxed">
            <!-- <p class="mb-4">Please review the claims and authority documents carefully before signing. If you have any questions, don't hesitate to contact us.</p>
            <a href="#" class="mb-4 text-blue-500 hover:text-blue-700" target="_blank">Click Here to View Documents</a> -->

            <h2 class="mb-4 font-bold pt-2">Summary Statements</h2>
           <!-- <p class="mb-4">I authorise Lewis Mitchell Solicitors to request information from identified car dealerships and/or lenders to process my claim, including the Credit Agreement and details about ancillary products. I consent to this information being released to Lewis Mitchell Solicitors and acknowledge that my claim may be referred to the Financial Ombudsman Service (FOS) if necessary.</p>
          -->
          </div>

          <!-- Signature Field 
          <SignatureField :signatureData="signatureData" @change="captureSignature" />
          <p class="text-red-500 text-xs italic" v-if="signatureError">{{ signatureError }}</p>
          -->

<!-- Agreement Checkbox -->
<div class="mb-4">
  <label class="flex items-start space-x-2">
    <input
      type="checkbox"
      class="form-checkbox text-green-500 w-5 h-5 mt-1"
      v-model="privacyPolicy"
    />
    <span class="text-sm text-gray-700 leading-relaxed">
      I authorise Lewis Mitchell Solicitors to request information from identified car dealerships and/or lenders to process my claim, including the Credit Agreement and details about ancillary products. I consent to this information being released to Lewis Mitchell Solicitors and acknowledge that my claim may be referred to the Financial Ombudsman Service (FOS) if necessary. I agree to the
      <a href="/privacy-policy" class="text-blue-500 hover:text-blue-700" target="_blank">Privacy Policy</a>.
    </span>
  </label>
  <p class="text-red-500 text-xs italic mt-1" v-if="privacyPolicyError">{{ privacyPolicyError }}</p>
</div>
</div>


        <div class="flex flex-col items-center">
          <!-- 'Process Claim' Button -->
          <button @click="submitForm" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2 pb-4">That's all, Process Claim</button>

          <!-- 'Previous' Link -->
          <a @click="previousStep" class="text-sm text-blue-500 hover:text-blue-700 cursor-pointer">Go back to the previous step</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import SignatureField from '@/components/SignatureField.vue';

const dataSoapApiKey = "3c6673da8ed24dadaffd5f7663631f5ed731304b6d24412a9c80384090af46c7";

export default {
  components: {
    //SignatureField,
  },
  props: {
    gclid: {
      type: String,
      default: '',
    },
    quizData: {
      type: Object,
      default: () => ({}),
    },
    utmCampaign: {
      type: String,
      default: '',
    },
    utmSource: {
      type: String,
      default: '',
    },
    utmMedium: {
      type: String,
      default: '',
    },
    utmKeyword: {
      type: String,
      default: '',
    },
    utmMatch: {
      type: String,
      default: '',
    },
    utmDevice: {
      type: String,
      default: '',
    },
  },
  name: "ContactForm",
  data() {
    return {
      carRegistrations: '',
      inputType: 'date', // Default to 'date' but can be changed to 'text'
      formSubmitted: false,
      currentStep: 1, // Initialize currentStep
      signatureData: null, // Add this property to hold signature data
      signatureError: '', // Add this property to hold signature error
      signatureWidth: 285,
      signatureHeight: 200,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      lastValidatedPhone: '',
      messageError: "",
      privacyPolicy: false,
      privacyPolicyError: "",
      isSubmitting: false,
      submitSuccess: false,
      submitError: false,
      dateOfBirth: '',
      dateOfBirthError: '',
      postcode: "",
      addressSuggestions: [],
      submitted: false,
      addressDetailsCache: {},
      suggestionsVisible: false, // Track visibility of suggestions
      selectedAddress: "", // Add this line
      addressError: "", // Add this line
      addressData: {
        street: '',
        city: '',
        postalCode: '',
        country: ''
      },
      quizDataMapping: {
        apiParam1: "purchased_car_finance",
        apiParam2: "told_about_commission",
        apiParam3: "finance_options_explained",
        apiParam4: "lender_name",
        apiParam5: "how_many_veichles",
        apiParam6: "finance_agreements_available",
        phoneHlrStatus: null,  // This will store the active status
        country: "", // New property for the country
      },
      localUtmCampaign: this.utmCampaign,
      localUtmSource: this.utmSource,
      localUtmMedium: this.utmMedium,
      localUtmKeyword: this.utmKeyword,
      localUtmDevice: this.utmDevice,
      localUtmMatch: this.utmMatch,
      localAdId: this.adId,
      localAdSetId: this.adSetId,
      localGclid: this.gclid,
    };
  },

  created() {
    if (this.localUtmCampaign) {
      localStorage.setItem("utm_campaign", this.localUtmCampaign);
    } else {
      const storedUtmCampaign = localStorage.getItem("utm_campaign");
      if (storedUtmCampaign) {
        this.localUtmCampaign = storedUtmCampaign;
      }
    }

    if (this.localGclid) {
      localStorage.setItem("gclid", this.localGclid);
    } else {
      const storedGclid = localStorage.getItem("gclid");
      if (storedGclid) {
        this.localGclid = storedGclid;
      }
    }

    if (this.localUtmSource) {
      localStorage.setItem("utm_source", this.localUtmSource);
    } else {
      const storedUtmSource = localStorage.getItem("utm_source");
      if (storedUtmSource) {
        this.localUtmSource = storedUtmSource;
      }
    }

    if (this.localUtmMedium) {
      localStorage.setItem("utm_medium", this.localUtmMedium);
    } else {
      const storedUtmMedium = localStorage.getItem("utm_medium");
      if (storedUtmMedium) {
        this.localUtmMedium = storedUtmMedium;
      }
    }

    if (this.localUtmKeyword) {
      localStorage.setItem("utm_keyword", this.localUtmKeyword);
    } else {
      const storedUtmKeyword = localStorage.getItem("utm_keyword");
      if (storedUtmKeyword) {
        this.localUtmKeyword = storedUtmKeyword;
      }
    }

    if (this.localUtmMatch) {
      localStorage.setItem("utm_matchtype", this.localUtmMatch);
    } else {
      const storedUtmMatch = localStorage.getItem("utm_matchtype");
      if (storedUtmMatch) {
        this.localUtmMatch = storedUtmMatch;
      }
    }

    if (this.localUtmDevice) {
      localStorage.setItem("utm_device", this.localUtmDevice);
    } else {
      const storedUtmDevice = localStorage.getItem("utm_device");
      if (storedUtmDevice) {
        this.localUtmDevice = storedUtmDevice;
      }
    }
  },

  mounted() {
    this.debounceSearch = this.debounce(this.searchAddress, 500);
    this.$nextTick(() => {
      this.adjustSignatureSize();
      window.addEventListener('resize', this.adjustSignatureSize, { passive: true });
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustSignatureSize);
  },

  methods: {
    captureSignature(signatureData) {
      this.signatureData = signatureData;
      this.signatureError = ""; // Clear the error when signature is captured
    },

    handlePostcodeInput() {
      this.debounceSearch();
    },

    toggleInputType() {
      this.inputType = this.inputType === 'date' ? 'text' : 'date';
    },

    validateAddress() {
      if (!this.selectedAddress) {
        this.addressError = "Please select an address.";
      } else {
        this.addressError = "";
      }
    },



    validateDateOfBirth() {
      if (!this.dateOfBirth) {
        this.dateOfBirthError = 'Please enter your date of birth.';
      } else {
        this.dateOfBirthError = '';
      }
    },

    handleAddressSelected(data) {
      this.addressData = {
        street: data.street,
        postalCode: data.postcode,
        country: data.country
      };
    },

    async searchAddress() {
      if (this.isValidPostcode(this.postcode)) {
        try {
          const postcodeWithoutSpaces = this.postcode.replace(/\s+/g, '');
          const response = await axios.get(`https://api.getAddress.io/autocomplete/${postcodeWithoutSpaces}?api-key=FDphHsSZX0C2onlo0FO_OA41517&all=true`);
          this.addressSuggestions = response.data.suggestions;
          this.suggestionsVisible = true;
        } catch (error) {
          console.error("Error fetching addresses:", error);
        }
      } else {
        this.suggestionsVisible = false;
      }
    },

    closeSuggestions() {
      this.suggestionsVisible = false;
    },

    debounce(func, delay) {
      let debounceTimer;
      return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    },

    isValidPostcode(postcode) {
      const regex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
      return regex.test(postcode);
    },

    async selectAddress(suggestion) {
      try {
        const response = await axios.get(`https://api.getAddress.io/get/${suggestion.id}?api-key=FDphHsSZX0C2onlo0FO_OA41517&all=true`);
        const { formatted_address, postcode, country } = response.data;
        const cityIndex = formatted_address.length > 2 ? formatted_address.length - 2 : -1;
        const city = cityIndex !== -1 ? formatted_address[cityIndex] : '';
        this.addressData = {
          street: formatted_address[0] || '',
          city: city,
          postalCode: postcode,
          country: country,
        };
        this.selectedAddress = `${this.addressData.street}, ${this.addressData.city}, ${this.addressData.postalCode}, ${this.addressData.country}`;
        this.addressError = "";
        this.suggestionsVisible = false;
      } catch (error) {
        console.error("Error selecting address:", error);
      }
    },

    async nextStep() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      await this.validatePhone();
      this.validateAddress();
      this.validateDateOfBirth();

      if (
        this.firstNameError ||
        this.lastNameError ||
        this.emailError ||
        this.phoneError ||
        this.dateOfBirthError ||
        this.addressError
      ) {
        return;
      }

      this.currentStep++;
    },

    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.formSubmitted = false;
      }
    },

    autosaveSignature(signatureData) {
      try {
        localStorage.setItem("signature", signatureData);
      } catch (error) {
        console.error("Error saving signature to local storage:", error);
      }
    },

    preventDefaultFocus(event) {
      event.preventDefault();
    },

    validateFirstName() {
      if (!this.firstName) {
        this.firstNameError = "First name is required";
      } else {
        this.firstNameError = "";
      }
    },

    adjustSignatureSize() {
      if (window.innerWidth < 768) {
        this.signatureWidth = 254;
        this.signatureHeight = 144;
      } else if (window.innerWidth >= 1024) {
        this.signatureWidth = 315;
        this.signatureHeight = 150;
      } else {
        this.signatureWidth = 285;
        this.signatureHeight = 200;
      }
    },

    validateLastName() {
      if (!this.lastName) {
        this.lastNameError = "Last name is required";
      } else {
        this.lastNameError = "";
      }
    },

    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.email) {
        this.emailError = "Email is required";
      } else if (!emailPattern.test(this.email)) {
        this.emailError = "Invalid email address";
      } else {
        this.emailError = "";
      }
    },

    validatePrivacyPolicy() {
      if (!this.privacyPolicy) {
        this.privacyPolicyError = "You must agree to the Summary Statement & Privacy Policy";
      } else {
        this.privacyPolicyError = "";
      }
    },

    async validatePhone() {
      const phonePattern = /^(\+?\d{1,4}[-\s]?)?(\d{1,4}[-\s]?){1,7}\d{1,4}$/;
      if (!this.phone) {
        this.phoneError = "Phone number is required";
      } else if (!phonePattern.test(this.phone)) {
        this.phoneError = "Invalid phone number";
      } else if (this.phone !== this.lastValidatedPhone) {
        try {
          const isValid = await this.validateMobileNumberWithDatasoap();
          if (isValid === false) {
            this.phoneError = "Invalid mobile number";
          } else {
            this.phoneError = "";
            this.lastValidatedPhone = this.phone;
          }
        } catch (error) {
          console.error("Error validating mobile number with DataSoap:", error);
          this.phoneError = "";
        }
      }
    },

    clearDateOfBirthError() {
      if (this.dateOfBirth) {
        this.dateOfBirthError = "";
      }
    },

    async validateMobileNumberWithDatasoap() {
      try {
        const response = await axios.post(
          'https://api.datasoap.co.uk/v2',
          {
            lookup: this.phone,
            checks: ['hlr']
          },
          {
            headers: {
              'Content-Type': 'application/json-patch+json',
              'accept': 'text/plain',
              'Authorization': `Bearer ${dataSoapApiKey}`
            }
          }
        );
        if (response.data.valid === true) {
          this.phoneHlrStatus = response.data.hlr.on ? 'active' : 'non-active';
          this.phoneError = "";
          return true;
        } else {
          this.phoneError = 'Invalid mobile number';
          return false;
        }
      } catch (error) {
        console.error('Error validating mobile number with DataSoap:', error);
        const phonePattern = /^(\+?\d{1,4}[-\s]?)?(\d{1,4}[-\s]?){1,7}\d{1,4}$/;
        if (!this.phone || !phonePattern.test(this.phone)) {
          this.phoneError = "Invalid phone number";
          return false;
        } else {
          this.phoneError = "";
          return true;
        }
      }
    },

    //validateSignature() {
      //if (!this.signatureData) {
        //this.signatureError = "Signature is required";
      //} else {
        //this.signatureError = "";
      //}
    //},

    async submitForm() {
      console.log("Submitting with address data:", this.addressData);
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      await this.validatePhone();
      this.validatePrivacyPolicy();
      //this.validateSignature();

      if (
        this.firstNameError || 
        this.lastNameError || 
        this.emailError || 
        (this.phoneError !== null && this.phoneError !== undefined && this.phoneError !== '') || 
        this.dateOfBirthError || 
        this.privacyPolicyError
        //this.signatureError
      ) {
        return;
      }

      this.isSubmitting = true;

      const config = {
        headers: {
          "Authorization": `Bearer ${process.env.VUE_APP_GO_HIGH_LEVEL_API_KEY}`,
          "Content-Type": "application/json",
        },
      };

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        dateOfBirth: this.dateOfBirth,
        address1: this.addressData.street,
        postalCode: this.addressData.postalCode,
        city: this.addressData.city,
        customField: {
          service_type: 'Car Finance Claim',
          car_registrations: this.carRegistrations,
          //signature_base64: this.signatureData,
          privacy_policy_agreed: this.privacyPolicy ? 'Yes' : 'No',
        }
      };

      Object.keys(this.quizData).forEach(key => {
        const fieldKey = this.quizDataMapping[key];
        if (fieldKey) {
          payload.customField[fieldKey] = this.quizData[key];
        }
      });

      console.log("Final payload:", payload);

      try {
        const response = await axios.post("https://rest.gohighlevel.com/v1/contacts/", payload, config);
        console.log(response);
        this.submitSuccess = true;
      } catch (error) {
        console.error("Error posting the form to Go High Level:", error);
        this.submitSuccess = false;
      }

      this.isSubmitting = false;

      if (this.submitSuccess) {
        window.location.href = "/thank-you";
      }
    },
  },
  computed: {
    isFormValid() {
      return (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.phone &&
        this.privacyPolicy
        //this.signatureData
      );
    },
  }
};
</script>

<style>

/* Existing CSS for Text Scaling */
@media (max-width: 640px) {
  .small-device-text {
    font-size: 0.8rem; /* Updated font size for small devices */
  }
}

/* CSS for Signature Field */
.signature-container {
  max-width: 100%;
  margin-bottom: 1rem;
  width: 285px;
  height: 200px;
  position: relative;
  overflow: visible;
}

.signature-field {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .signature-container {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
}

canvas {
  touch-action: none;
}

.selected-address {
  background-color: #f9fafb;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.selected-address p {
  margin: 0;
  font-size: 0.875rem;
}

.selected-address p:first-child {
  font-weight: 600;
  color: #374151;
}

@media (max-width: 600px) {
  input[type="date"] {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    color: #333333;
  }
}

</style>
